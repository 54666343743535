<div class="layout-header-pusher header-single-link"></div>

<div class="layout-header header-single-link">
  <div class="header-content">
    <div class="container">

      <a href="" class="btn btn-tertiary ms-n2 mw-none" (click)="goBack($event)">
        <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'./assets/svg/arrow-left-24.svg'"></span>
        <span class="align-middle">{{ 'action.back' | translate }}</span>
      </a>

    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoadingMaterial">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoadingMaterial">
    <ng-container *ngIf="!material || material.isUnavailable">
      <div class="article-unavailable">
        <p class="text-muted mt-2 mb-0">{{ 'pages.default.patient_materials.unavailable' | translate }}</p>
      </div>
    </ng-container>

    <ng-container *ngIf="material && !material.isUnavailable">
      <div class="article-content">
        <!-- content-head - start -->
        <!-- (should always be here, is not in the repeater loop) -->
        <div class="content-head">
          <!-- <p class="small text-muted mb-2 mb-lg-3" *ngIf="material?.disclaimer"><i>{{ material?.disclaimer}}</i></p> -->
          <h1 class="mb-1">{{ material?.title}}</h1>
          <p class="introduction">{{ material?.description }}</p>
        </div>
        <!-- content-head - end -->

        <!-- content-cover - start -->
        <div class="content-cover" *ngIf="material?.displayThumb()"
          [style.background-image]="'url(' + material?.thumbnail?.image?.url + '?width=1640&disable=upscale&format=pjpg&auto=webp' + ' )' | safe:'style'">
        </div>
        <!-- content-cover - end -->


        <ng-container *ngFor="let paragraph of material?.paragraphs">
          <div *ngIf="paragraph.type === 'text'" [innerHtml]="paragraph.text" class="content-partial"></div>

          <div *ngIf="paragraph.type === 'banner'  && paragraph?.image_position === 'Right'" class="content-feature">
            <div class="feature-body">
              <div class="title" [innerHtml]="paragraph.text"></div>

              <a href="{{ paragraph?.link?.href }}" target="_blank" rel="noopener noreferrer" class="btn btn-primary mt-2"
                *ngIf="paragraph?.link?.href">
                <span class="align-middle">{{ (paragraph?.link.title)? paragraph?.link.title:paragraph?.link?.href
                  }}</span>
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-24.svg'"></span>
              </a>
            </div>
            <div class="feature-image"
              [style.background-image]="'url(' + paragraph?.image?.url + '?width=926&disable=upscale&format=pjpg&auto=webp' + ')' | safe:'style'">

            </div>
          </div>

          <div *ngIf="paragraph.type === 'banner'  && paragraph?.image_position === 'Left'" class="content-feature">
            <div class="feature-image"
              [style.background-image]="'url(' + paragraph?.image?.url + '?width=926&disable=upscale&format=pjpg&auto=webp' + ')' | safe:'style'">
            </div>
            <div class="feature-body">
              <div class="title" [innerHtml]="paragraph.text"></div>

              <a href="{{ paragraph?.link?.href }}" target="_blank" rel="noopener noreferrer" class="btn btn-primary mt-2"
                *ngIf="paragraph?.link?.href">
                <span class="align-middle">{{ (paragraph?.link.title)? paragraph?.link.title:paragraph?.link?.href
                  }}</span>
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-24.svg'"></span>
              </a>
            </div>
          </div>

          <!-- Banner Top -->
          <div *ngIf="paragraph.type === 'banner'  && paragraph?.image_position === 'Top'" class="content-feature-top">
            <div class="feature-image"
              [style.background-image]="'url(' + paragraph?.image?.url + '?width=1640&disable=upscale&format=pjpg&auto=webp' + ')'">
            </div>
            <div class="feature-body">
              <div class="title" [innerHtml]="paragraph.text"></div>

              <a href="{{ paragraph?.link?.href }}" target="_blank" rel="noopener noreferrer" class="btn btn-primary mt-2"
                *ngIf="paragraph?.link?.href">
                <span class="align-middle">{{ (paragraph?.link.title)? paragraph?.link.title:paragraph?.link?.href
                  }}</span>
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-24.svg'"></span>
              </a>
            </div>
          </div>
          <!-- /Banner Top -->

          <!-- Banner Bottom -->
          <div *ngIf="paragraph.type === 'banner'  && paragraph.image_position === 'Bottom'" class="content-feature-bottom">
            <div class="feature-body">
              <div class="title" [innerHtml]="paragraph.text"></div>

              <a href="{{ paragraph?.link?.href }}" target="_blank" rel="noopener noreferrer" class="btn btn-primary mt-2"
                *ngIf="paragraph?.link?.href">
                <span class="align-middle">{{ (paragraph?.link.title)? paragraph?.link.title:paragraph?.link?.href
                  }}</span>
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-24.svg'"></span>
              </a>
            </div>
            <div class="feature-image"
              [style.background-image]="'url(' + paragraph?.image?.url + '?width=1640&disable=upscale&format=pjpg&auto=webp' + ')'">
            </div>
          </div>
          <!-- /Banner Bottom -->

          <div class="content-audio" *ngIf="paragraph.type === 'audio'">
            <div class="audio-player">
              <p class="title">{{ paragraph.file?.title}}</p>
              <audio preload="auto" autobuffer controls controlsList="nodownload" (play)="setProgress(100)">
                <source [src]="paragraph.file?.url" />
              </audio>
            </div>
          </div>

          <div class="content-pdf" *ngIf="paragraph.type === 'pdf'">
            <div class="single-item-block m-0">
              <div class="block-icon">
                <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/file-pdf-24.svg'"></span>
              </div>
              <div class="block-wrapper">
                <p class="label">{{ paragraph.file?.title}}</p>
                <p class="value">{{ 'pages.default.patient_materials.pdf_file' | translate}}</p>
              </div>
              <div class="block-btn-wrapper">
                <a href="{{ paragraph.file?.url }}" target="_blank" class="block-action btn btn-lg btn-tertiary btn-icon" rel=noopener
                (click)="setProgress(100)">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/download-24.svg'"></span>
              </a>
              </div>
            </div>
          </div>

          <!-- Video -->
          <div class="content-video" *ngIf="paragraph.type === 'video' && paragraph?.videos">
            <app-video-carousel class="position-absolute w-100 h-100" [videos]="paragraph.videos" (load)="setProgress(100)"></app-video-carousel>
          </div>
          <!-- /Video -->

          <div class="content-image" *ngIf="paragraph.type === 'image' && paragraph?.file">
            <img [src]="paragraph.file?.url + '?width=1640&disable=upscale&format=pjpg&auto=webp'" />
          </div>
        </ng-container>

        <!-- content-footer - start -->
        <div class="content-footer" *ngIf="material?.disclaimer">
          <p class="small text-muted mb-2 mb-lg-3"><i>{{ material?.disclaimer}}</i></p>
        </div>
        <!-- content-footer - end -->
      </div>

      <!-- extra space - start -->
      <div class="py-4"></div>
      <!-- extra space - end -->

      <!-- Extra materials -->
      <div class="row">
        <div class="col">
          <div class="heading-block mb-3">
            <div class="block-wrapper overflow-hidden">
              <h6 class="text-truncate">{{ 'pages.default.patient_materials.motivational_title' | translate }}</h6>
              <p class="lead text-truncate">{{ 'pages.default.patient_materials.motivational_description' | translate }}</p>
            </div>
            <div class="block-wrapper m-start-auto">
              <div class="progress-bar-wrapper m-end-2">
                <label>{{ 'pages.default.patient_materials.read_stats' | translate:{value: (phase?.completed), total:
                  phase?.total} | translateNumber}}</label>
                <div class="progress">
                  <div class="progress-bar" role="progressbar" [style.width]="phase?.percent()"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row g-3">
        <div class="col-4" *ngFor="let material of unread">

          <a href="" class="card preview-card h-100"
            [ngClass]="{
              'completed': material.isCompleted,
              'unavailable': material.isUnavailable
            }"
            (click)="openMaterial($event, material)"
          >
            <div class="card-cover">
              <img *ngIf="material?.thumbnail?.image?.url" [src]="material?.thumbnail?.image?.url + '?width=814&disable=upscale&format=pjpg&auto=webp' " alt="Thumbnail" />

              <div class="cover-meta">
                <div class="meta-category" *ngIf="material.thumbnail">
                  <span [inlineSVG]="'./assets/svg/file-general-24.svg'" *ngIf="material.isArticle" class="rtl-mirrored-inline-svg"></span>
                  <span [inlineSVG]="'./assets/svg/file-pdf-24.svg'" *ngIf="material.isDocument"></span>
                  <span [inlineSVG]="'./assets/svg/play-24.svg'" *ngIf="material.isMedia" class="rtl-mirrored-inline-svg"></span>
                </div>
              </div>
            </div>
            <div class="card-body">
              <p class="lead fw-semibold e2e-material-title">{{ material.title || 'pages.default.patient_materials.unavailable' | translate }}</p>
              <p class="fw-normal mb-0 preview-description e2e-material-description">{{ material?.description }}</p>
            </div>
          </a>

        </div>
      </div>
    </ng-container>
  </div>
</div>
