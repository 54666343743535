import { PhaseType } from '../enums/phase-type';
import { TranslationInterface } from '../interfaces/translation.interface';
import { Material } from './material';

export class MaterialPhase {
  public name: TranslationInterface;
  public description: TranslationInterface; //used in patient-materials
  public translated_description: string;
  public total = 0;
  public completed = 0;
  public educational_materials: Material[];
  public phase_id: string;
  public sub_phases: MaterialPhase[];

  public type: PhaseType; //Used and filled in patient-materials

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    if (item.phase) {
      this.name = item.phase.name;
      this.phase_id = item.phase.id;
    }

    this.total = item.total;
    if (item.completed) {
      this.completed = item.completed.individual;
    }

    // Load educational material for the highest level
    this.educational_materials = [];

    item.educational_materials?.forEach(result => {
      const material = new Material(result);
      material.phaseName = this.name;
      this.educational_materials.push(material);
    });

    // Load educational material for sub level 1s.
    this.sub_phases = [];

    if (item.sub_phases && item.sub_phases?.length) {
      item.sub_phases.forEach(_sub_phase => {
        const sub_phase = new MaterialPhase(_sub_phase);

        // All lower level phase materials are mapped to level 1 subphases.
        // Because we never show lower level phases but need the materials.
        if (sub_phase.sub_phases?.length) {
          const lowerLevelSubPhases = sub_phase.sub_phases;

          lowerLevelSubPhases.forEach((sp: MaterialPhase) => {
            sub_phase.educational_materials.push(...this.getLowerPhaseMaterials(sp))
          });
        }

        this.sub_phases.push(sub_phase);
      });
    }

    this.educational_materials = this.educational_materials.sort(this.order);

  }

  order(a, b) {
    if (a.progress < b.progress) {
      return -1;
    }
    if (a.progress > b.progress) {
      return 1;
    }

    if (a.progress === b.progress) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
    }
    return 0;
  }

  percent() {
    return ((this.completed / this.total) * 100).toString() + '%';
  }

  get isFinished(): boolean {
    return this.completed && this.total && (this.completed >= this.total);
  }

  get translationKey(): string {
    return `shared.${this.name?.region}.${this.name?.key}`;
  }

  getLowerPhaseMaterials(phase: MaterialPhase): Material[] {
    let materials: Material[] = [];

    if (phase.educational_materials?.length) {
      materials.push(...phase.educational_materials);
    }

    if (phase.sub_phases?.length) {
      phase.sub_phases.forEach((subPhase: MaterialPhase) => materials.push(...this.getLowerPhaseMaterials(subPhase)));
    }

    // Return materials without duplicates
    return [...new Map(materials.map(m => [m.uid, m])).values()];
  }

  public get hasLowerPhaseMaterials(): boolean {
    return !!this.getLowerPhaseMaterials(this)?.length;
  }

  public get isSubPhase(): boolean {
    return this.type === PhaseType.SUBPHASE;
  }
}
